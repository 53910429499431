import React, { useEffect, useState } from "react";
import WaitingListWrapper from "../../wrappers/WailtingListWrapper/WaitingListWrapper";
import styles from "./styles.module.css";
import Paragraph from "../../ui/Paragragh/Paragraph";
import { getEmailList, postEmail } from "../../utils/service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withVisibility from "../../hoc/withVisibility/withVisibility";

const WaitingListWithVisibility = withVisibility(WaitingListWrapper);

function WaitingList() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // eslint-disable-next-line
  const [maxValue, setMaxValue] = useState(1000); // Changed to number type
  const [currentValue, setCurrentValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  // eslint-disable-next-line
  const [response, setResponse] = useState(null);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const result = await getEmailList();
      setData(result?.data || []);
      setCurrentValue(result?.data.length || 0);
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const payload = { data: { email } };
    const confirmEmail = data.filter((item) => item?.attributes.email === email);
    if(email.trim()?.length === 0){
      setLoading(false);
      setError('Does not accept empty field');
    }
    else if (email.trim()?.length>0 && Array.isArray(confirmEmail) && confirmEmail.length === 0) {
      try {
        const result = await postEmail(payload);
        setResponse(result);
        toast.success("Email was added successfully");
        setEmail("");
        fetchData();
      } catch (err) {
        toast.error(err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    } else {
      setError("Email already exists in the database.");
      toast.error("Email already exists in the database.");
      setLoading(false);
    }
  };

  return (
    <div className={styles.WaitingList}>
      <ToastContainer position="top-center" />
      <WaitingListWithVisibility
        setEmail={setEmail}
        setError={setError}
        error={error}
        handleSubmit={handleSubmit}
        loading={loading}
        email={email}
      />
      <div className={styles.SpotText}>
        <span>
          <Paragraph
            fontSize="16px"
            fontWeight="600"
            className={styles.SpotSize}
            text="Secure Your Spot & "
          />
          &nbsp;
          <Paragraph
            color="rgba(0, 0, 0, 0.5)"
            fontSize="16px"
            fontWeight="500"
            className={styles.SpotSize}
            text="Be a part of it from day one."
          />
        </span>
      </div>
      <div className={styles.progressBarContainer}>
        <progress
          className={styles.progressBar}
          value={Math.min(currentValue, maxValue)}
          max={maxValue}
        ></progress>
        <div style={{ marginLeft: ".75rem" }}>
          <Paragraph
            fontWeight="600"
            className={styles.SpotSize}
            text={Math.min(currentValue, maxValue)}
          />
          &nbsp;/&nbsp;
          <Paragraph
            color="rgba(0, 0, 0, 0.5)"
            fontWeight="600"
            className={styles.SpotSize}
            text={maxValue}
          />
        </div>
      </div>
    </div>
  );
}

export default WaitingList;
